let {nav, infoConfig} = window.platformData() || {};

let Vue = window.Vue;

Vue.component('geetest-verification', {
    template: `
        <div class="geetest-verification">
            <slot :loading="loading"
                :verify="verify"
                :reset="reset"></slot>
        </div>
    `,
    props: {
        authUrl: {
            type: String,
            required: true
        },
        params: Object
    },
    data () {
        return {
            loading: false,
            captcha: null
        };
    },
    methods: {
        verify (resolve) {
            if (this.captcha) {
                this.captcha.verify();
            } else {
                this.loading = true;
                window.axios({
                    url: this.authUrl,
                    method: 'post',
                    data: window.Qs.stringify(Object.assign({}, this.params))
                }).then(response => {
                    let data = response.data;
                    if (data) {
                        let initGeetest = window.initGeetest;
                        if (typeof initGeetest === 'function') {
                            initGeetest({
                                'gt': data['gt'],
                                'challenge': data['challenge'],
                                'offline': !data.success,
                                'new_captcha': data['new_captcha'],
                                'product': 'bind',
                                onError: () => {
                                    this.$message.warning('验证数据获取失败');
                                    this.loading = false;
                                }
                            }, (captcha) => {
                                captcha.onSuccess(() => {
                                    if (typeof resolve === 'function') {
                                        resolve(captcha.getValidate());
                                    }
                                });
                                captcha.onReady(() => {
                                    this.loading = false;
                                    this.$nextTick(() => {
                                        captcha.verify();
                                    });
                                });
                                this.captcha = captcha;
                            });
                        }
                    }
                }, () => {
                    this.$message.warning('验证数据加载失败');
                    this.loading = false;
                });
            }
        },
        reset () {
            let captcha = this.captcha;
            if (captcha) {
                captcha.reset();
                this.captcha = null;
            }
        }
    }
});
Vue.component('head-login', {
    template: `
        <div class="head-login">
            <div class="header">
                <div class="menus" flex="cross:center">
                    <span class="menu" :class="{'is-active': loginType === '0'}" @click="onMenuClick('0')">验证码登录</span>
                    <span class="menu" :class="{'is-active': loginType === '1'}" @click="onMenuClick('1')">密码登录</span>
                </div>
                <el-dialog v-if="updateAccount"
                               :visible.sync="updateAccount"
                               :close-on-click-modal="false"
                               width="620px"
                               title="账号完善"
                               custom-class="head-user-info-dialog"
                               append-to-body>
                        <div class="update-account-model">
                            <div class="account-content">
                                <div class="describe-message">为了您的账号安全，请先进行账号完善，完善后您可以使用手机号或账号登录系统。</div>
                                <div class="user-account">
                                    <el-input type="text" v-model="userAccount" placeholder="输入账号，设置后不允许修改，建议包含6-20位字母、数字组合。" maxlength="20" minlength="6"></el-input>
                                </div>
                            </div>
                            <div class="account-foot">
                                <el-button class="cancel-btn" type="button" @click="updateAccount = false">取消</el-button>
                                <el-button class="summit-btn" type="button" @click="tokenLogin">保存</el-button>
                            </div>
                        </div>
                    </el-dialog>
                <el-dialog v-if="updatePhone"
                               :visible.sync="updatePhone"
                               :close-on-click-modal="false"
                               width="620px"
                               title="手机号完善"
                               custom-class="head-user-info-dialog"
                               :append-to-body="true">
                   <div class="update-account-model">
                            <div class="account-content">
                                <div class="describe-message">根据国家法律规定为了您的账号安全，请您响应国家政策先绑定手机实名认证再进行操作，绑定后您可以使用手机号或账号进行登录。</div>
                                <div class="user-account">
                                    <el-form size="large">
                                        <el-form-item>
                                            <el-input v-model="form.mobilePhone"
                                                type="text"
                                                placeholder="输入手机号"
                                                maxlength="11"></el-input>
                                        </el-form-item>
                                        <el-form-item>
                                            <el-input v-model="form.smsCode"
                                                type="text"
                                                placeholder="输入验证码">
                                                <template #suffix>
                                                    <geetest-verification auth-url="/index/authBehavior/smsAuth">
                                                        <template #default="geetestData">
                                                            <el-button type="text"
                                                                :loading="geetestData.loading"
                                                                :disabled="verificationCode.delaySec > 0"
                                                                @click="sendSmsCode(geetestData.verify, geetestData.reset, 1)">
                                                                <template v-if="geetestData.loading">
                                                                    <span>验证加载中...</span>
                                                                </template>
                                                                <template v-else>
                                                                    <span v-if="verificationCode.delaySec > 0">{{verificationCode.delaySec}} s</span>
                                                                    <span v-else>获取验证码</span>
                                                                </template>
                                                            </el-button>
                                                        </template>
                                                    </geetest-verification>
                                                </template>
                                            </el-input>
                                        </el-form-item>
                                    </el-form>
                                </div>
                            </div>
                            <div class="account-foot">
                                <el-button class="cancel-btn" type="button" @click="updatePhone = false">取消</el-button>
                                <el-button class="summit-btn" type="button" @click="bindPhone">保存</el-button>
                            </div>
                        </div>
                </el-dialog>
            </div>
            <template v-if="loginType === '0'">
                <div class="main">
                    <el-form size="large">
                        <el-form-item>
                            <el-input v-model="form.mobilePhone"
                                type="text"
                                placeholder="输入手机号"
                                maxlength="11"
                                @keyup.enter.native="startLogin"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-input v-model="form.smsCode"
                                type="text"
                                placeholder="输入验证码"
                                @keyup.enter.native="startLogin">
                                <template #suffix>
                                    <geetest-verification auth-url="/index/authBehavior/smsAuth">
                                        <template #default="geetestData">
                                            <el-button type="text"
                                                :loading="geetestData.loading"
                                                :disabled="verificationCode.delaySec > 0"
                                                @click="sendSmsCode(geetestData.verify, geetestData.reset, 2)">
                                                <template v-if="geetestData.loading">
                                                    <span>验证加载中...</span>
                                                </template>
                                                <template v-else>
                                                    <span v-if="verificationCode.delaySec > 0">{{verificationCode.delaySec}} s</span>
                                                    <span v-else>获取验证码</span>
                                                </template>
                                            </el-button>
                                        </template>
                                    </geetest-verification>
                                </template>
                            </el-input>
                        </el-form-item>
                    </el-form>
                </div>
                <div class="footer" flex="main:justify cross:center">
                    <div>
                        <el-button type="primary"
                            round
                            :loading="committing"
                            @click="startLogin">{{committing ? '登录中...' : '立即登录'}}</el-button>
                    </div>
                    <!--<el-button >扫码登录</el-button>-->
<!--                    <div class="tip-group">-->
<!--                        <span>未注册手机号自动注册登录</span>-->
<!--                    </div>-->
                </div>
                <div class="wechat-login">
                   <span class="line-span"></span>
                   <a class="qr-code-a" @click="qrCodeLogin">
                       <i class="iconfont icon-weixin"></i>
                       <span>微信登录</span>
                   </a>
                   <span class="line-span"></span>
               </div>
                <div class="manager-login">
                   <a href="/login">
                      <span>管理员登录，请点击此处</span>
                      <img src="/static/images/login/manager_login.png">
                   </a>
                </div>
            </template>

            <!-- 密码登录 -->
            <template v-else-if="loginType === '1'">
                <geetest-verification auth-url="/index/authBehavior/login">
                      <template #default="geetestData">
                        <div class="main">
                            <el-form size="large">
                                <el-form-item>
                                    <el-input v-model="form.loginId"
                                        type="text"
                                        placeholder="输入账号或手机号"
                                        @keyup.enter.native="startLogin(geetestData.verify, geetestData.reset)"
                                        ></el-input>
                                </el-form-item>
                                <el-form-item>
                                    <el-input v-model="form.password"
                                        type="password"
                                        placeholder="输入密码"
                                        @keyup.enter.native="startLogin(geetestData.verify, geetestData.reset)"
                                        ></el-input>
                                </el-form-item>
                            </el-form>
                        </div>
                        <div class="footer" flex="main:justify cross:center">
                            <div>
                                <el-button type="primary"
                                    round
                                    :disabled="geetestData.loading"
                                    :loading="committing"
                                    @click="startLogin(geetestData.verify, geetestData.reset)">{{committing ? '登录中...' : '立即登录'}}</el-button>
                            </div>
                            <div class="tip-group">
                                <el-button size="mini"
                                    type="text"
                                    @click="toRegister" v-if="flagIsStudentRegister==='1'">注册</el-button>
                                <el-button size="mini"
                                    type="text"
                                    @click="toForgetPwd">忘记密码</el-button>
                            </div>
                        </div>
                        <div class="wechat-login">
                           <span class="line-span"></span>
                           <a class="qr-code-a" @click="qrCodeLogin">
                               <i class="iconfont icon-weixin"></i>
                               <span>微信登录</span>
                           </a>
                           <span class="line-span"></span>
                       </div>
                       <div class="manager-login">
                           <a href="/login">
                              <span>管理员登录，请点击此处</span>
                              <img src="/static/images/login/manager_login.png">
                           </a>
                       </div>
                    </template>
                </geetest-verification>
            </template>
        </div>
    `,
    mixins: [window.globalMixin, window.utilsMixin],
    data () {
        return {
            loginType: '0',
            form: {
                mobilePhone: '',
                smsCode: '',
                loginId: '',
                password: ''
            },
            verificationCode: {
                delaySec: 0
            },
            committing: false,
            timer: null,
            updateAccount: false,
            updatePhone: false,
            userAccount: '',
            ssoUserIdStr: '',
            loginToken: '',
            loading: false,
            flagIsStudentRegister:'0'
        };
    },
    created() {
        this.getCanRegister()
    },
    methods: {
        getCanRegister(){
            window.axios({
                url: '/index/account/getCanRegister',
                method: 'post',
            }).then(response => {
                let data = response.data;
                if (data.errorCode === 0) {
                    this.flagIsStudentRegister = data.flagIsStudentRegister
                }
            });
        },
        onMenuClick (type) {
            this.loginType = type;
            this.startDelay(0);
        },
        toRegister () {
            this.$emit('to-register');
        },
        toForgetPwd () {
            this.$emit('to-forget-pwd');
        },
        sendSmsCode (verify, reset, code) {
            let url = '/index/sms/send/quickLogin';
            if (code === 1) {
                url = '/index/sms/send/bindPhone';
            }
            if (!this.form.mobilePhone) {
                this.$message.warning('请输入手机号');
                return;
            }
            if (!this.isMobilePhone(this.form.mobilePhone)) {
                this.$message.warning('输入的手机号不合法');
                return;
            }
            verify(params => {
                window.axios({
                    url: url,
                    method: 'post',
                    data: window.Qs.stringify(Object.assign({
                        mobilePhone: this.form.mobilePhone
                    }, params))
                }).then(response => {
                    let data = response.data;
                    if (data.errorCode === 0) {
                        this.$message.success(data.message || '验证码发送成功');
                        this.startDelay(data.codeTime || 30);
                    } else {
                        this.$message.warning(data.message || '验证码发送失败');
                        reset();
                    }
                }, () => {
                    this.$message.warning('验证码发送失败');
                });
            });
        },
        startLogin (verify, reset) {
            switch (this.loginType) {
                case '0': {
                    if (!this.form.mobilePhone) {
                        this.$message.warning('请输入手机号');
                        return;
                    }
                    if (!this.isMobilePhone(this.form.mobilePhone)) {
                        this.$message.warning('输入的手机号不合法');
                        return;
                    }
                    if (!this.form.smsCode) {
                        this.$message.warning('请输入验证码');
                        return;
                    }
                    this.committing = true;
                    let params = {
                        mobilePhone: this.form.mobilePhone,
                        password: this.$md5(this.form.password),
                        smsCode: this.form.smsCode
                    };
                    window.axios({
                        url: '/index/userSmsLogin',
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded',
                            'Accept': 'text/html, */*',
                            'X-Requested-With': 'XMLHttpRequest'
                        },
                        method: 'post',
                        data: window.Qs.stringify(params)
                    }).then(response => {
                        let data = response.data;
                        if (data) {
                            if (data.errorCode === 0) {
                                this.$emit('hide');
                                this.$nextTick(() => {
                                    this.reloadUrl();
                                });
                            } else if (data.error && data.error === '1') {
                                this.$emit('hide');
                                this.updateAccount = true;
                            } else {
                                let errorMessage = data.message
                                this.$message.warning(errorMessage || '用户登录失败');
                                if(errorMessage === '当前手机号未注册，即将跳转到注册页面，请完成注册后登录。'){
                                    this.$message.warning(errorMessage)
                                    let that = this
                                    setTimeout(function () {
                                        that.toRegister()
                                    }, 500)
                                }
                            }
                        }
                    }, () => {
                        this.$message.warning('登录信息发送失败');
                    }).finally(() => {
                        this.committing = false;
                    });
                    break;
                }
                case '1': {
                    if (!this.form.loginId) {
                        this.$message.warning('请输入登录账号');
                        return;
                    }
                    if (!this.form.password) {
                        this.$message.warning('请输入密码');
                        return;
                    }
                    verify(params => {
                        this.committing = true;
                        window.axios({
                            url: '/index/userLogin',
                            method: 'post',
                            headers: {
                                'Content-Type': 'application/x-www-form-urlencoded',
                                'Accept': 'text/html, */*',
                                'X-Requested-With': 'XMLHttpRequest'
                            },
                            data: window.Qs.stringify(Object.assign({
                                loginId: this.form.loginId,
                                password: this.$md5(this.form.password)
                            }, params))
                        }).then(response => {
                            let data = response.data;
                            if (data) {
                                if (data.errorCode === 0) {
                                    this.$emit('hide');
                                    this.$nextTick(() => {
                                        this.reloadUrl();
                                    });
                                } else if (data.error && data.error === '1') {
                                    // 绑定账户
                                    this.$emit('hide');
                                    this.updateAccount = true;
                                } else if (data.error && data.error === '2') {
                                    // 绑定手机号
                                    this.$emit('hide');
                                    this.updatePhone = true;
                                } else {
                                    this.$message.warning(data.message || '用户登录失败');
                                    reset();
                                }
                            }
                        }, () => {
                            this.$message.warning('登录信息发送失败');
                        }).finally(() => {
                            this.committing = false;
                        });
                    });
                    break;
                }
            }
        },
        startDelay (sec) {
            this.verificationCode.delaySec = sec;
            if (sec > 0) {
                this.timer=setTimeout(() => {
                    this.startDelay(sec - 1);
                }, 1000);
            } else {
                clearTimeout(this.timer);
                this.verificationCode.delaySec = 0;
            }
        },
        tokenLogin () {
            if (this.loading) {
                return;
            }
            if (!this.isAccount(this.userAccount)) {
                this.$message.warning('账号格式不正确，请重新输入');
                return;
            }
            this.loading = true;
            if (this.loginType === '0') {
                window.axios({
                    url: '/index/userSmsLogin',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Accept': 'text/html, */*',
                        'X-Requested-With': 'XMLHttpRequest'
                    },
                    method: 'post',
                    data: window.Qs.stringify({
                        mobilePhone: this.form.mobilePhone,
                        smsCode: this.form.smsCode,
                        account: this.userAccount
                    })
                }).then(response => {
                    let data = response.data;
                    if (data.errorCode === 0) {
                        this.$emit('hide');
                        this.$nextTick(() => {
                            this.reloadUrl();
                        });
                    } else if (data.error && data.error === '1') {
                        // 绑定账户
                        this.$emit('hide');
                        this.updateAccount = true;
                    }  else {
                        this.$message.warning(data.message || '用户登录失败');
                    }
                }, () => {
                    this.$message.warning('登录信息发送失败');
                }).finally(() => {
                    this.loading = false;
                });
            } else if (this.loginType === '1') {
                let other = {
                    account: this.userAccount
                };
                window.axios({
                    url: '/index/userLogin',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Accept': 'text/html, */*',
                        'X-Requested-With': 'XMLHttpRequest'
                    },
                    method: 'post',
                    data: window.Qs.stringify({
                        loginId: this.form.loginId,
                        password: this.$md5(this.form.password),
                        other: JSON.stringify(other)
                    })
                }).then(response => {
                    let data = response.data;
                    if (data.errorCode === 0) {
                        this.$emit('hide');
                        this.$nextTick(() => {
                            this.reloadUrl();
                        });
                    } else if (data.error && data.error === '1') {
                        // 绑定账户
                        this.$emit('hide');
                        this.updateAccount = true;
                    } else if (data.error && data.error === '2') {
                        // 绑定手机号
                        this.$emit('hide');
                        this.updatePhone = true;
                    } else {
                        this.$message.warning(data.message || '用户登录失败');
                    }
                }, () => {
                    this.$message.warning('登录信息发送失败');
                }).finally(() => {
                    this.loading = false;
                });
            }
        },
        bindPhone () {
            if (this.loading) {
                return;
            }
            if (!this.form.mobilePhone) {
                this.$message.warning('请输入手机号');
                return;
            }
            if (!this.isMobilePhone(this.form.mobilePhone)) {
                this.$message.warning('输入的手机号不合法');
                return;
            }
            if (!this.form.smsCode) {
                this.$message.warning('请输入验证码');
                return;
            }
            this.loading = true;
            let other = {
                mobilePhone: this.form.mobilePhone,
                smsCode: this.form.smsCode
            };
            let params = {
                loginId: this.form.loginId,
                password: this.$md5(this.form.password),
                other: JSON.stringify(other)
            };
            window.axios({
                url: '/index/userLogin',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Accept': 'text/html, */*',
                    'X-Requested-With': 'XMLHttpRequest'
                },
                method: 'post',
                data: window.Qs.stringify(params)
            }).then(message => {
                if (message) {
                    if (message.data.errorCode === 0) {
                        this.$emit('hide');
                        this.$nextTick(() => {
                            this.reloadUrl();
                        });
                    } else {
                        this.$message.error(message.data.message || '登录失败!');
                    }
                } else {
                    this.$message.warning(message.data.message || '用户登录失败');
                }
            }).finally(() => {
                this.loading = false;
            });
        },
        qrCodeLogin () {
            window.location.href = '/index/account/verifyUserLogin?path=' + encodeURIComponent(encodeURIComponent('/learning/home'))  + '&type=1';
        }
    }
});
Vue.component('head-register', {
    template: `
        <div class="head-register">
            <div class="header">
                <span class="title">欢迎注册</span>
            </div>
            <div class="main">
                <el-form size="large">
                    <el-form-item>
                        <el-input v-model="form.account"
                            type="text"
                            placeholder="输入账号，建议包含6-20位字母、数字组合"
                            maxlength="20" minlength="6"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-input v-model="form.mobilePhone"
                            type="text"
                            placeholder="输入手机号"
                            maxlength="11"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-input v-model="form.smsCode"
                            type="text"
                            placeholder="输入验证码">
                            <template #suffix>
                                <geetest-verification auth-url="/index/authBehavior/smsAuth">
                                    <template #default="geetestData">
                                        <el-button type="text"
                                            :loading="geetestData.loading"
                                            :disabled="verificationCode.delaySec > 0"
                                            @click="sendSmsCode(geetestData.verify, geetestData.reset)">
                                            <template v-if="geetestData.loading">
                                                <span>验证加载中...</span>
                                            </template>
                                            <template v-else>
                                                <span v-if="verificationCode.delaySec > 0">{{verificationCode.delaySec}} s</span>
                                                <span v-else>获取验证码</span>
                                            </template>
                                        </el-button>
                                    </template>
                                </geetest-verification>
                            </template>
                        </el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-input v-model="form.password"
                            type="password"
                            placeholder="密码需包含大小写字母和数字，长度8-20位"
                            maxlength="20"></el-input>
                    </el-form-item>
                    <div class="agreement">
                        <template v-if="infoConfig.registrationAgreement || infoConfig.privacyAgreement">
                            <el-checkbox v-model="agreementChecked">
                            </el-checkbox>
                            <span class="agree">同意</span>
                            <span class="registration" v-if="infoConfig.registrationAgreement" @click="registrationAgreement = true">《注册协议》</span>
                            <span class="andConnector" v-if="infoConfig.registrationAgreement && infoConfig.privacyAgreement">&</span>
                            <span class="privacy" v-if="infoConfig.privacyAgreement" @click="privacyAgreement = true">《隐私政策》</span>
                        </template>
                    </div>
                </el-form>
            </div>
            <div class="footer" flex="main:justify cross:center">
                <div>
                    <el-button type="primary"
                        round
                        :loading="committing"
                        @click="startRegister">{{committing ? '注册中...' : '立即注册'}}</el-button>
                </div>
                <div>
                    <span class="tip-group">已有账号？</span>
                    <el-button size="mini"
                        type="text"
                        @click="toLogin">登录</el-button>
                </div>
            </div>
            <el-dialog v-if="registrationAgreement"
                       :visible.sync="registrationAgreement"
                       :close-on-click-modal="false"
                       width="620px"
                       title="注册协议"
                       custom-class="head-user-info-dialog"
                       append-to-body>
                <div class="registration-agreement-model">
                    <div v-html="infoConfig.registrationAgreement"></div>
                </div>
            </el-dialog>
            <el-dialog v-if="privacyAgreement"
                       :visible.sync="privacyAgreement"
                       :close-on-click-modal="false"
                       width="620px"
                       title="隐私政策"
                       custom-class="head-user-info-dialog"
                       append-to-body>
                <div class="privacy-agreement-model">
                    <div v-html="infoConfig.privacyAgreement"></div>
                </div>
            </el-dialog>
        </div>
    `,
    mixins: [window.globalMixin, window.utilsMixin],
    data () {
        return {
            infoConfig,
            agreementChecked: false,
            registrationAgreement: false,
            privacyAgreement: false,
            form: {
                mobilePhone: '',
                account: '',
                smsCode: '',
                password: ''
            },
            verificationCode: {
                delaySec: 0
            },
            committing: false
        };
    },
    methods: {
        toLogin () {
            this.$emit('to-login');
        },
        sendSmsCode (verify, reset) {
            if (!this.form.mobilePhone) {
                this.$message.warning('请输入手机号');
                return;
            }
            if (!this.isMobilePhone(this.form.mobilePhone)) {
                this.$message.warning('输入的手机号不合法');
                return;
            }
            verify(params => {
                window.axios({
                    url: '/index/sms/send/register',
                    method: 'post',
                    data: window.Qs.stringify(Object.assign({
                        mobilePhone: this.form.mobilePhone
                    }, params))
                }).then(response => {
                    let data = response.data;
                    if (data && data.errorCode === 0) {
                        this.$message.success(data.message || '验证码发送成功');
                        this.startDelay(data.timeDelay || 30);
                    } else {
                        this.$message.warning(data.message || '验证码发送失败');
                        reset();
                    }
                }, () => {
                    this.$message.warning('验证码发送失败');
                });
            });
        },
        startRegister () {
            this.$message.closeAll();
            if (!this.form.account) {
                this.$message.warning('请输入账号');
                return;
            }
            if (!this.form.mobilePhone) {
                this.$message.warning('请输入手机号');
                return;
            }
            if (!this.isMobilePhone(this.form.mobilePhone)) {
                this.$message.warning('输入的手机号不合法');
                return;
            }
            if (!this.form.smsCode) {
                this.$message.warning('请输入验证码');
                return;
            }
            if (!this.form.password) {
                this.$message.warning('请输入密码');
                return;
            }
            if (!this.isComplexPassword(this.form.password)) {
                this.$message.warning('输入的密码不合法');
                return;
            }
            if (!this.agreementChecked && this.infoConfig.registrationAgreement && this.infoConfig.privacyAgreement) {
                this.$message.warning('请阅读并同意注册协议和隐私政策');
                return;
            } else if (!this.agreementChecked && this.infoConfig.registrationAgreement) {
                this.$message.warning('请阅读并同意注册协议');
                return;
            } else if (!this.agreementChecked && this.infoConfig.privacyAgreement) {
                this.$message.warning('请阅读并同意隐私政策');
                return;
            }
            this.committing = true;
            let params = {
                account: this.form.account,
                mobilePhone: this.form.mobilePhone,
                password: this.$md5(this.form.password),
                smsCode: this.form.smsCode
            };
            window.axios({
                url: '/index/account/sendRegister',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Accept': 'text/html, */*',
                    'X-Requested-With': 'XMLHttpRequest'
                },
                method: 'post',
                data: window.Qs.stringify(params)
            }).then(response => {
                let data = response.data;
                if (data && data.errorCode === 0) {
                    this.$emit('hide');
                    this.$message({
                        message: data.message || '用户注册成功',
                        type: 'success',
                        duration: 1000,
                        onClose: () => {
                            this.reloadUrl();
                        }
                    });
                } else {
                    this.$message.warning(data.message || '用户注册失败');
                }
            }, () => {
                this.$message.warning('注册信息发送失败');
            }).finally(() => {
                this.committing = false;
            });
        },
        startDelay (sec) {
            this.verificationCode.delaySec = sec;
            if (sec > 0) {
                setTimeout(() => {
                    this.startDelay(sec - 1);
                }, 1000);
            }
        }
    },
    mounted () {
        console.log(this.infoConfig);
    },
});
Vue.component('head-forget-pwd', {
    template: `
        <template v-if="!findPwdToken">
            <div class="head-forget-pwd">
                <div class="header">
                    <span class="title">找回密码</span>
                </div>
                <div class="main">
                    <el-form size="large">
                        <el-form-item>
                            <el-input v-model="form.mobilePhone"
                                type="text"
                                placeholder="输入手机号"
                                maxlength="11"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-input v-model="form.smsCode"
                                type="text"
                                placeholder="输入验证码">
                                <template #suffix>
                                     <geetest-verification auth-url="/index/authBehavior/smsAuth">
                                        <template #default="geetestData">
                                            <el-button type="text"
                                                :loading="geetestData.loading"
                                                :disabled="verificationCode.delaySec > 0"
                                                @click="sendSmsCode(geetestData.verify, geetestData.reset)">
                                                <template v-if="geetestData.loading">
                                                    <span>验证加载中...</span>
                                                </template>
                                                <template v-else>
                                                    <span v-if="verificationCode.delaySec > 0">{{verificationCode.delaySec}} s</span>
                                                    <span v-else>获取验证码</span>
                                                </template>
                                            </el-button>
                                        </template>
                                    </geetest-verification>
                                </template>
                            </el-input>
                        </el-form-item>
                    </el-form>
                </div>
                <div class="footer" flex="cross:center">
                    <div>
                        <el-button type="primary"
                            round
                            :loading="committing"
                            @click="requestReset">{{committing ? '请求中...' : '确认'}}</el-button>
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="head-forget-pwd">
                <div class="header">
                    <span class="title">重置密码</span>
                </div>
                <div class="main">
                    <el-form size="large">
                        <el-form-item>
                            <el-input v-model="form.password"
                                type="password"
                                placeholder="密码需包含大小写字母和数字，长度8-20位"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-input v-model="form.confirmPassword"
                                type="password"
                                placeholder="再次输入密码"></el-input>
                        </el-form-item>
                    </el-form>
                </div>
                <div class="footer" flex="cross:center">
                    <div>
                        <el-button type="primary"
                            round
                            :loading="committing"
                            @click="startReset">{{committing ? '请求中...' : '确认'}}</el-button>
                    </div>
                </div>
            </div>
        </template>
    `,
    mixins: [window.utilsMixin],
    data () {
        return {
            form: {
                mobilePhone: '',
                smsCode: '',
                password: '',
                confirmPassword: ''
            },
            verificationCode: {
                delaySec: 0
            },
            committing: false,
            findPwdToken: null,
            accountId: '' // 重置密码accountId
        };
    },
    methods: {
        sendSmsCode (verify, reset) {
            this.$message.closeAll();
            if (!this.form.mobilePhone) {
                this.$message.warning('请输入手机号');
                return;
            }
            if (!this.isMobilePhone(this.form.mobilePhone)) {
                this.$message.warning('输入的手机号不合法');
                return;
            }
            verify(params => {
                window.axios({
                    url: '/index/sms/send/forgetPwd',
                    method: 'post',
                    data: window.Qs.stringify(Object.assign({
                        mobilePhone: this.form.mobilePhone
                    }, params))
                }).then(response => {
                    let data = response.data;
                    if (data && data.errorCode === 0) {
                        this.$message.success(data.message || '验证码发送成功');
                        this.startDelay(data.timeDelay || 30);
                    } else {
                        this.$message.warning(data.message || '验证码发送失败');
                        reset();
                    }
                }, () => {
                    this.$message.warning('验证码发送失败');
                });
            });
        },
        requestReset () {
            this.$message.closeAll();
            if (!this.form.mobilePhone) {
                this.$message.warning('请输入手机号');
                return;
            }
            if (!this.isMobilePhone(this.form.mobilePhone)) {
                this.$message.warning('输入的手机号不合法');
                return;
            }
            if (!this.form.smsCode) {
                this.$message.warning('请输入验证码');
                return;
            }
            this.committing = true;
            let params = {
                mobilePhone: this.form.mobilePhone,
                smsCode: this.form.smsCode
            };
            window.axios({
                url: '/index/account/resetPwd',
                method: 'post',
                data: window.Qs.stringify(params)
            }).then(response => {
                let data = response.data;
                if (data && data.errorCode === 0) {
                    this.findPwdToken = data.token || '';
                    this.accountId = data.accountId || '';
                } else {
                    this.$message.warning(data.message || '找回密码失败');
                }
            }, () => {
                this.$message.warning('找回密码请求失败');
            }).finally(() => {
                this.committing = false;
            });
        },
        startReset () {
            this.$message.closeAll();
            if (!this.form.password) {
                this.$message.warning('请输入密码');
                return;
            }
            if (!this.isComplexPassword(this.form.password)) {
                this.$message.warning('输入的密码不合法');
                return;
            }
            if (!this.form.confirmPassword) {
                this.$message.warning('请再次输入密码');
                return;
            }
            if (this.form.confirmPassword !== this.form.password) {
                this.$message.warning('两次输入密码不一致');
                return;
            }
            this.committing = true;
            window.axios({
                url: '/index/account/submitResetPwd',
                method: 'post',
                data: window.Qs.stringify({
                    accountId: this.accountId || '',
                    token: this.findPwdToken || '',
                    password: this.$md5(this.form.password)
                })
            }).then(response => {
                let data = response.data;
                if (data && data.errorCode === 0) {
                    this.$message.success(data.message || '重置密码成功');
                    this.$emit('to-login');
                    this.$nextTick(() => {
                        this.findPwdToken = '';
                        this.accountId = '';
                    });
                } else {
                    this.$message.warning(data.message || '重置密码失败');
                }
            }, () => {
                this.$message.warning('重置密码请求失败');
            }).finally(() => {
                this.committing = false;
            });
        },
        startDelay (sec) {
            this.verificationCode.delaySec = sec;
            if (sec > 0) {
                setTimeout(() => {
                    this.startDelay(sec - 1);
                }, 1000);
            }
        }
    },
    watch: {
        findPwdToken (val) {
            if (!val) {
                Object.keys(this.form).forEach(key => {
                    this.form[key] = '';
                });
            }
        }
    }
});

let HeadVm = new Vue({
    el: '#head-container',
    mixins: [window.globalMixin],
    data () {
        return {
            nav,
            fixedMenuNum: 5,
            showPopper: false,
            popperType: '',
            searchCourse: '',
            headerType: 0,
            popperHook: {
                login: null
            },
            isClick: true,
            questionnaireDialogVisible: false,
            noticeMessageDialogVisible: false
        };
    },
    computed: {
        fixedMenus () {
            let nav = this.nav || [];
            if (nav.length > this.fixedMenuNum) {
                return nav.slice(0, this.fixedMenuNum);
            } else {
                return nav;
            }
        },
        popupMenus () {
            let nav = this.nav || [];
            if (nav.length > this.fixedMenuNum) {
                return nav.slice(this.fixedMenuNum);
            } else {
                return null;
            }
        },
        getPopper () {
            switch (this.popperType) {
                case 'login':
                    return 'head-login';
                case 'register':
                    return 'head-register';
                case 'forgetPwd':
                    return 'head-forget-pwd';
            }
        }
    },
    created () {
        this.searchCourse = this.pageQuery.searchText || '';
        this.checkQuestionnaire()
        this.checkHshNotice()
    },
    methods: {
        isNavActive (item) {
            switch (item.linkTypeCode) {
                case 'home':
                    return this.pageUri === '/learning/home' || this.pageUri === '' || this.pageUri === '/';
                case 'courseDetail':
                    return this.pageUri === '/learning/courseList';
                case 'certificateQuery':
                    return this.pageUri === '/learning/certificateQuery';
                default:
                    return this.pageQuery.navId === item.id;
            }
        },
        onUserLogin (hook) {
            this.popperType = 'login';
            this.popperHook.login = hook;
            this.$nextTick(() => {
                this.showPopper = true;
            });
        },
        onUserRegister () {
            this.popperType = 'register';
            this.$nextTick(() => {
                this.showPopper = true;
            });
        },
        onForgetPwd () {
            this.popperType = 'forgetPwd';
            this.$nextTick(() => {
                this.showPopper = true;
            });
        },
        onUserLogout () {
            this.$confirm('确定退出吗, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                confirmButtonClass:'confirmButton',
                type: 'warning'
            }).then(() => {
                window.axios({
                    url: '/index/account/logout',
                    method: 'post'
                }).then(response => {
                    let data = response.data;
                    if (data && data.errorCode === 0) {
                        this.toUrl('/learning/home');
                    } else {
                        this.$message.error(data.message || '注销退出失败!');
                    }
                }, () => {
                    this.$message.warning('注销退出失败');
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });
            });

        },
        goCart () {
            this.toUrl('/learning/cart');
        },
        goSearch () {
            this.$message.closeAll();
            if (this.searchCourse ==='') {
                return this.$message.warning({
                    message: '请输入感兴趣课程',
                    duration: 1000
                });
            } else {
                this.toUrl('/learning/search?searchText=' + this.searchCourse);
            }
        },
        goMyStudy () {
            switch (this.loginUserModel.userType.code) {
                case 'STUDENT':
                case 'TEACHER':
                    this.toUrl('/learning/personalCenter');
                    break;
                // case 'TEACHER':
                //     this.toUrl('/learning/teacherCenter');
                //     break;
                case 'MANAGER':
                    this.toUrl(this.loginUserModel.location);
                    break;
            }
        },
        goHome () {
            this.toUrl('/learning/home');
        },
        goMyAccount () {
            switch (this.loginUserModel.userType.code) {
                case 'STUDENT':
                case 'TEACHER':
                    this.toUrl('/learning/personalCenter?module=account');
                    break;
                // case 'TEACHER':
                //     this.toUrl('/learning/teacherCenter?module=account');
                //     break;
                case 'MANAGER':
                    this.toUrl(this.loginUserModel.location);
                    break;
            }
        },
        goNavPage (item) {
            if (item.typeCode === '0') {
                switch (item.linkTypeCode) {
                    case 'home':
                        this.toUrl('/learning/home');
                     break;
                    case 'courseDetail':
                        this.toUrl('/learning/courseList');
                        break;
                    case 'certificateQuery':
                        this.toUrl('/learning/certificateQuery');
                        break;
                    default :this.toUrl('/learning/news?navId='+ item.id);
                }
            } else if (item.typeCode === '1'){
                this.toUrl('/learning/textImage?navId=' + item.id);
            }else if (item.typeCode === '2'){
                this.toUrl('/learning/news?navId=' + item.id);
            } else if (item.typeCode === '3'){
                if (item.isBlank) {
                    let w = window.open();
                    setTimeout(() => {
                        w.location.href = item.link
                    })
                } else {
                    this.toUrl(item.link);
                }
            }
        },
        checkQuestionnaire(){
            let whitePageUrlList = ['/learning/login']
            let pagePath = location.pathname
            if(whitePageUrlList.includes(pagePath)) {
                return false
            }
            let hasShowQuestionnaireDialog = Cookies.get('hasShowQuestionnaireDialog')
            if(this.loginUserModel && this.loginUserModel.isHasQuestionnaire && (!hasShowQuestionnaireDialog || hasShowQuestionnaireDialog=='false')){
                // 弹窗提示
                this.questionnaireDialogVisible = true
            }
        },
        checkHshNotice(){
            if(this.loginUserModel && this.loginUserModel.noticeObj){
                this.noticeMessageDialogVisible = true;
            } else {
                this.noticeMessageDialogVisible = false;
            }
        },
        toJion () {
            this.toUrl('/learning/personalCenter?module=questionnaire&tabIndex=1');
            Cookies.set('hasShowQuestionnaireDialog', 'true')
        },
        handleQuestionnaireDialogClose(done){
            Cookies.set('hasShowQuestionnaireDialog', 'true')
            done()
        }
    },
    watch: {
        showPopper (val) {
            if (!val) {
                Object.keys(this.popperHook).forEach(key => {
                    let hook = this.popperHook[key];
                    if (typeof hook === 'function') {
                        hook(false);
                    }
                    this.popperHook[key] = null;
                });
            }
        }
    }
});
